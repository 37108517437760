import Hero from "../../components/hero/hero";
import Footer from "../../components/footer/footer";
import HeroImg from "../../_resources/img/aboutHero.png";
import Img1 from "../../_resources/img/Sec3Img2.png";
import "./about.css";
import Img2 from "../../_resources/img/rightArrowBlack.png";
import Img3 from "../../_resources/img/aboutLastImg.jpg";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <Hero
        img={HeroImg}
        heading={`Unique Journeys with Local Expertise`}
        subHeading={`Let us tailor your perfect adventure`}
      />
      <section className="about--sec02">
        <div className="about--sec02Content">
          <h3 className="about--title">Welcome to SL Safaris</h3>
          <p className="about--desc">
            SLSafaris is a dedicated team passionate about showcasing the best
            of Sri Lanka through expert local knowledge and personalized travel
            services. Specializing in curated experiences that highlight the
            island's natural beauty and cultural richness, we ensure seamless
            journeys with reliable logistics and a commitment to exceptional
            service standards.
          </p>
          <ul className="about--list">
            <li className="about--listItem">
              Expertly curated travel experiences.
            </li>
            <li className="about--listItem">
              Reliable logistics and premium transport options.
            </li>
            <li className="about--listItem">
              Commitment to high service standards.
            </li>
            <li className="about--listItem">
              Focus on guest satisfaction and memorable journeys.
            </li>
            <li className="about--listItem">
              Exceptional value with competitive pricing.
            </li>
          </ul>
        </div>
        <img className="about--img" src={Img1} />
      </section>
      <section className="about--sec03">
        <div className="about--vmRow">
          <h3 className="about--title about--center">Our Vision</h3>
          <p className="about--para">
            To be the leading provider of unique and unforgettable travel
            experiences in Sri Lanka, inspiring global travelers to discover the
            island's natural beauty, rich culture, and warm hospitality
          </p>
        </div>
        <div className="about--vmRow">
          <h3 className="about--title about--center">Our Mission</h3>
          <p className="about--para">
            To deliver exceptional and personalized travel experiences in Sri
            Lanka, showcasing the island's beauty while supporting local
            communities and preserving the environment
          </p>
        </div>
      </section>
      <section className="about--sec04">
        <div className="about--sec04content">
          <h2 className="about--sec04Title">Plan your journey</h2>
          <p className="about--sec04Para">
            Plan your perfect Sri Lankan adventure with our curated tours and
            experiences.
          </p>
          <Link to={"/contact"} className="about--link">
            Contact Now!
            <img src={Img2} className="about--linkImg"/>
          </Link>
        </div>
        <img className="about--sec04Img" src={Img3} />
      </section>
      <Footer />
    </>
  );
}
