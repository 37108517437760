import React from "react";
import { useParams } from "react-router-dom";
import dest from "../../data/destinations";
import Experiences from "../../data/experiences";
import Det from "../../components/det/det";
import "./viewDest.css";

export default function ViewDest() {
  const { id } = useParams(); // Extract id from URL params

  const destination = dest.find(item => {
    console.log(item); // Log each item to verify
    return item.id === parseInt(id); // Return true if item id matches parsed id
  });

  const expereinces = Experiences.filter((item) => item.destination === parseInt(id));

  console.log(expereinces.length);

  return (
    <>
    <div className="destination--overlay">

    </div>
      <Det
        heroImg={`/_resources/destinations/${destination.img}`}
        dest={expereinces}
        type={`experiences`}
        val1={destination.title}
        val2={destination.desc}
        desc={`
          Embark on a curated journey through Sri Lanka's most captivating sights and experiences. Our tours are designed to showcase the best of the island, from historical landmarks to breathtaking landscapes. Let us guide you on a memorable adventure.
        `}
      />
    </>
  );
}
