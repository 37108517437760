import "./reviewCard.css";
import Quote from "./_resources/quotation.png";

export default function Review() {
  return (
    <div className="rvCard">
      <img src={Quote} className="rvCard--quote" />
      <p className="rvCard--para">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris
      </p>
      <span className="rvCard--name">- H. L. Reed</span>
    </div>
  );
}
