import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/header/header";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Destinations from "./pages/destinations/destinations";
import ViewDest from "./pages/viewDestination/viewDest";
import Tours from "./pages/tours/tours";
import Experiences from "./pages/experiences/experiences";
import { useEffect, useState } from "react";

function App() {
  const [page, setPage] = useState(0);
  return (
    <Router>
      <Header itemList={[
        { name: "Home", path: "/" },
        { name: "About", path: "/about" },
        { name: "Destinations", path: "/destinations" },
        { name: "Tours", path: "/tours" },
        { name: "Expereinces", path: "/experiences" },
        { name: "Contact Us", path: "/contact" }]} />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/destinations"} element={<Destinations />} />
        <Route path={"/destination/:id"} element={<ViewDest />} />
        <Route path={"/tours"} element={<Tours />} />
        <Route path={"/experiences"} element={<Experiences />} />
      </Routes>
    </Router>
  );
}

export default App;
