import "../gallery/gallery.css";

export default function Gallery() {
    return(
        <div className="gallery--wrapper">
            <div className="gallery--column">
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/dolphin.png"}/>
                </div>
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/snorkelling.png"}/>
                </div>
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/food1.png"}/>
                </div>
            </div>
            <div className="gallery--column">
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/dolphin.png"}/>
                </div>
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/dolphin.png"}/>
                </div>
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/dolphin.png"}/>
                </div>
            </div>
            <div className="gallery--column">
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/dolphin.png"}/>
                </div>
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/dolphin.png"}/>
                </div>
                <div className="gallery--photo">
                    <img src={"/_resources/experiences/dolphin.png"}/>
                </div>
            </div>
        </div>
    )
}