import React, { useEffect } from "react";
import "./header.css";
import logo from "../../_resources/logo.png";
import closeIcon from "../../_resources/img/Delete.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Header = ({ itemList }) => {
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        document.getElementById("navbar").classList.add("scrolled");
      } else {
        document.getElementById("navbar").classList.remove("scrolled");
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleSideBar = () => {
    document.getElementById("sidebar").classList.toggle("show");
    document.getElementById("lastRow").classList.toggle("show");
  };

  return (
    <header className="header--container">
      <nav className="header--navbar" id="navbar">
        <img src={logo} className="header--logo" alt="Logo" />
        <ul className="header--navItems">
          <li>
            <Link to="/" className={location.pathname == "/" ? "header--navItemSelected" : "header--navItem"} id="navItem1">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className={location.pathname == "/about" ? "header--navItemSelected" : "header--navItem"} id="navItem2">
              About
            </Link>
          </li>
          <li>
            <Link to="/destinations" className={location.pathname == "/destinations" ? "header--navItemSelected" : "header--navItem"} id="navItem3">
              Destinations
            </Link>
          </li>
          <li>
            <Link to="/experiences" className={location.pathname == "/experiences" ? "header--navItemSelected" : "header--navItem"} id="navItem4">
              Experiences
            </Link>
          </li>
          <li>
            <Link to="/tours" className={location.pathname == "/tours" ? "header--navItemSelected" : "header--navItem"} id="navItem5">
              Tours
            </Link>
          </li>
          <li>
            <Link to="/contact" className={location.pathname == "/contact" ? "header--navItemSelected" : "header--navItem"} id="navItem6">
              Contact Us
            </Link>
          </li>
        </ul>
        <button className="header--toggleBtn" onClick={toggleSideBar}>
          <div className="header--toggleBtnRow"></div>
          <div className="header--toggleBtnRow"></div>
          <div className="header--lastToggleBtnRow" id="lastRow"></div>
        </button>
      </nav>
      <div className="header--sidebar" id="sidebar">
        <div className="header--sidebarContent">
          <img
            src={closeIcon}
            className="header--closeBtn"
            alt="Close"
            onClick={toggleSideBar}
          />
          <img src={logo} className="header--sidebarLogo" alt="Sidebar Logo" />
          <div className="header--sidebarItems">
            <Link to="/" className="header--sidebarItem" onClick={toggleSideBar}>
              Home
            </Link>
            <Link to="/about" className="header--sidebarItem" onClick={toggleSideBar}>
              About
            </Link>
            <Link to="/destinations" className="header--sidebarItem" onClick={toggleSideBar}>
              Destinations
            </Link>
            <Link to="/experiences" className="header--sidebarItem" onClick={toggleSideBar}>
              Experiences
            </Link>
            <Link to="/tours" className="header--sidebarItem" onClick={toggleSideBar}>
              Tours
            </Link>
            <Link to="/contact" className="header--sidebarItem" onClick={toggleSideBar}>
              Contact
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
