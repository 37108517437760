import React, { useState } from "react";
import heroImg from "../../_resources/img/toursHero.png";
import dest from "../../data/tours";
import "./tours.css";
import Det from "../../components/det/det";

export default function Tours() {
  return (
    <Det
      heroImg={heroImg}
      dest={dest}
      type={`tours`}
      val1={`Curated Journeys Across Sri Lanka`}
      val2={`From Historical Treasures to Scenic Delights`}
      desc={
        `
            Embark on a curated journey through Sri Lanka's most captivating sights and experiences. Our tours are designed to showcase the best of the island, from historical landmarks to breathtaking landscapes. Let us guide you on a memorable adventure.
        `
      }
    />
  );
}
