import React, { useState } from "react";
import Card from "../../components/card/card";
import Hero from "../../components/hero/hero";
import PaginationArrow from "../../components/card/_resources/more.png";
import Footer from "../../components/footer/footer";
import "./det.css";

const Det = ({ heroImg, dest, type, val1, val2, desc}) => {
  console.log(val1);
  console.log(val2);
  const itemsPerPage = 9;
  const [crSet, setCrSet] = useState(1);

  const totalSets = Math.ceil(dest.length / itemsPerPage);

  const getCurrentSet = () => {
    const startIndex = (crSet - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return dest.slice(startIndex, endIndex);
  };

  const handleNextSet = () => {
    if (crSet < totalSets) {
      setCrSet(crSet + 1);
    }
  };

  const handlePrevSet = () => {
    if (crSet > 1) {
      setCrSet(crSet - 1);
    }
  };

  const handlePageChange = (page) => {
    setCrSet(page);
    scroll();
  };

  function scroll() {
    const scrollToPosition = () => {
      window.scrollTo({
        top: window.innerHeight,
        behavior: "smooth",
      });
    };
    return null;
  }

  const renderPaginationButtons = () => {
    const buttons = [];

    buttons.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={
          crSet === 1 ? "dest--paginationBtn active" : "dest--paginationBtn"
        }
      >
        1
      </button>
    );

    if (crSet > 3) {
      buttons.push(<span key="dots-start">...</span>);
    }

    if (crSet > 2) {
      buttons.push(
        <button
          key={crSet - 1}
          onClick={() => handlePageChange(crSet - 1)}
          className="dest--paginationBtn"
        >
          {crSet - 1}
        </button>
      );
    }

    if (crSet !== 1 && crSet !== totalSets) {
      buttons.push(
        <button
          key={crSet}
          onClick={() => handlePageChange(crSet)}
          className="dest--paginationBtn active"
        >
          {crSet}
        </button>
      );
    }

    if (crSet < totalSets - 1) {
      buttons.push(
        <button
          key={crSet + 1}
          onClick={() => handlePageChange(crSet + 1)}
          className="dest--paginationBtn"
        >
          {crSet + 1}
        </button>
      );
    }

    if (crSet < totalSets - 2) {
      buttons.push(<span key="dots-end">...</span>);
    }

    if(totalSets != 1){
        buttons.push(
            <button
              key={totalSets}
              onClick={() => handlePageChange(totalSets)}
              className={
                crSet === totalSets
                  ? "dest--paginationBtn active"
                  : "dest--paginationBtn"
              }
            >
              {totalSets}
            </button>
          )
    };

    return buttons;
  };

  return (
    <>
      <Hero img={heroImg} heading={val1} subHeading={val2} />
      <section className="dest--sec01">
        <h2 className="dest--title">
            {type}
        </h2>
        <p className="dest--desc">{desc}</p>
        <div className="dest--cardWrapper">
          {getCurrentSet().map((destination) => (
            <Card
              key={destination.id}
              title={destination.title}
              desc={destination.desc}
              img={`/_resources/${type}/${destination.img}`}
              readMore={destination.readmore ? `/destination/${destination.id}`: null}
            />
          ))}
        </div>
        <div className="dest--btnRow">
          <button
            onClick={handlePrevSet}
            className="dest--paginationBtn"
            disabled={crSet === 1}
          >
            <img
              className="dest--paginationBtnIcon"
              id="prevBtn"
              src={PaginationArrow}
            />
          </button>
          {renderPaginationButtons()}
          <button
            onClick={handleNextSet}
            className="dest--paginationBtn"
            disabled={crSet === totalSets}
          >
            <img className="dest--paginationBtnIcon" src={PaginationArrow} />
          </button>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Det;
