import React, { useState } from "react";
import heroImg from "../../_resources/img/destinationsHero.png";
import dest from "../../data/destinations";
import "./destinations.css";
import Det from "../../components/det/det";

export default function Destinations() {
  return (
    <Det
      heroImg={heroImg}
      dest={dest}
      type={`destinations`}
      val1={`Discover Sri Lanka's Enchanting Destinations`}
      val2={`Explore Ancient Cities, Pristine Beaches, and Natural Wonders`}
      desc={`Explore the diverse and enchanting destinations
        of Sri Lanka. From ancient cities to pristine beaches, each
        location offers a unique glimpse into the island's rich culture and natural beauty. Discover your next unforgettable adventure here.`}
    />
  );
}
