import "../hero/hero.css";
import ScrollToTopOnMount from "../scrollToTop";

export default function Hero({img,heading,subHeading}) {
    return(
        <>
        <ScrollToTopOnMount/>
        <div className="hero" style={{backgroundImage: `url(${img})`}}>
            <div className="hero--row1">
                <h2 className="hero--heading">{heading}&nbsp;<span className="hero--endDiv">&nbsp;&nbsp;</span></h2>
            </div>
            <div className="hero--hrDiv"></div>
            <div className="hero--row2"> 
                <h5 className="hero--subHeading">{subHeading}</h5>
            </div>
        </div>
        </>
    )
}