import React, { useEffect, useRef, useState } from "react";
import Hero from "../../components/hero/hero";
import Footer from "../../components/footer/footer";
import HeroImg from "../../_resources/img/contactHero.png";
import Phone from "../../_resources/img/phone.png";
import Email from "../../_resources/img/email.png";
import Fb from "../../_resources/img/Facebook.png";
import Ig from "../../_resources/img/Instagram.png";
import Wa from "../../_resources/img/Whatsapp.png";
import Yt from "../../_resources/img/Youtube.png";
import Img1 from "../../_resources/img/contactImg.jpg";
import Form from "../../components/contactForm/form";
import "../../components/footer/footer.css";
import "./contact.css";

export default function Contact() {
  return (
    <>
      <Hero
        img={HeroImg}
        heading={`Unique Journeys with Local Expertise`}
        subHeading={`Let us tailor your perfect adventure`}
      />
      <section className="contact--sec01">
        <div className="contact--sec01Content">
          <h3 className="contact--sec01Title">Contact Us</h3>
          <p className="contact--sec01Desc">
            Get in touch with SL Safaris for personalized travel advice and
            booking inquiries.
          </p>
          <div className="contact--labelsRow">
            <div className="contact--label">
              <img src={Phone} className="contact--labelImg" />
              <div className="contact--labelContent">
                <span className="contact--labelName">Mobile</span>
                <br />
                <span className="contact--labelImg">0112 432 345</span>
              </div>
            </div>
            <div className="contact--label">
              <img src={Email} className="contact--labelImg" />
              <div className="contact--labelContent">
                <span className="contact--labelName">Email</span>
                <br />
                <span className="contact--labelImg">info@slsafaris.com</span>
              </div>
            </div>
          </div>
          <div className="contact--smDiv">
            <a className="contact--smLink" href="https://facebook.com">
              <img src={Wa} className="contact--smIcon" />
            </a>
            <a className="contact--smLink" href="https://facebook.com">
              <img src={Fb} className="contact--smIcon" />
            </a>
            <a className="contact--smLink" href="https://facebook.com">
              <img src={Ig} className="contact--smIcon" />
            </a>
            <a className="contact--smLink" href="https://facebook.com">
              <img src={Yt} className="contact--smIcon" />
            </a>
          </div>
          <h3 className="contact--sec01Title2">Send us an inquiry</h3>
          <Form />
        </div>
        <div className="contact--imgDiv">
          <img className="contact--img" src={Img1} />
        </div>
      </section>
      <Footer />
    </>
  );
}
