import { Link } from "react-router-dom";
import MoreIcon from "../card/_resources/more.png";
import "../card/card.css";

export default function Card({id,img,title,desc,readMore}) {
  return (
    <div className="card">
      <div
        className="card--imgDiv"
        style={{ backgroundImage: `url(${img})` }}
      ></div>
      <div className="card--contentDiv">
        <h3 className="card--title">{title}</h3>
        <p className="card--desc">
          {desc}
        </p>
        { readMore ?
        <Link to={readMore} className="card--readMore">Details&nbsp;<img src={MoreIcon} className="card--moreImg"/></Link>
        : ""}
      </div>
    </div>
  );
}
