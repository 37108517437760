import React, { useState } from "react";
import heroImg from "../../_resources/img/experiencesHero.png";
import dest from "../../data/experiences";
import "./experiences.css";
import Det from "../../components/det/det";

export default function Experiences() {
  return (
    <Det
      heroImg={heroImg}
      dest={dest}
      type={`experiences`}
      val1={`Authentic Experiences in Sri Lanka`}
      val2={`Adventure, Culture, and Relaxation Awaits`}
      desc={
        `Dive into the heart of Sri Lanka with our handpicked experiences. Whether you're seeking adventure, relaxation, or cultural immersion, our experiences cater to every traveler's dream. Make lasting memories with unique and authentic activities.`
      }
    />
  );
}
