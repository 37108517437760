import { Link } from "react-router-dom";
import "./footer.css";
import Logo from "./_resources/logo.png";
import Fb from "./_resources/Facebook.png";
import Ig from "./_resources/Instagram.png";
import Wa from "./_resources/Whatsapp.png";
import Yt from "./_resources/Youtube.png";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer--column" id="footerColumn1">
          <h5 className="footer--heading" id="heading1">Navigation</h5>
          <br />
          <Link to={"/"} className="footer--navLink">
            Home
          </Link>
          <Link to={"/about"} className="footer--navLink">
            About Us
          </Link>
          <Link to={"/destinations"} className="footer--navLink">
            Destinations
          </Link>
          <Link to={"/experience"} className="footer--navLink">
            Experiences
          </Link>
          <Link to={"/tours"} className="footer--navLink">
            Tours
          </Link>
          <Link to={"/contact"} className="footer--navLink">
            Contact Us
          </Link>
          <Link to={"/"} className="footer--navLink">
            Privacy Policies
          </Link>
        </div>
        <div className="footer--column" id="footerColumn2">
          <h5 className="footer--heading">Stay Connected</h5>
          <div className="footer--smRow">
            <div className="footer--smIconDiv">
              <a href="http://www.facebook.com" className="footer--iconLink">
                <img src={Wa} className="footer--smIcon" />
              </a>
            </div>
            <div className="footer--smIconDiv">
              <a href="http://www.facebook.com" className="footer--iconLink">
                <img src={Ig} className="footer--smIcon" />
              </a>
            </div>
            <div className="footer--smIconDiv">
              <a href="http://www.facebook.com" className="footer--iconLink">
                <img src={Yt} className="footer--smIcon" />
              </a>
            </div>
            <div className="footer--smIconDiv">
              <a href="http://www.facebook.com" className="footer--iconLink">
                <img src={Fb} className="footer--smIcon" />
              </a>
            </div>
          </div>
          <img src={Logo} className="footer--logo" />
        </div>
        <div className="footer--column">
          <h5 className="footer--heading" id="heading2">SL Safari</h5>
          <h5 className="footer--subHeading">Address</h5>
          <span className="footer--span">
            No 1, Sample Street,
            <br />
            Gala palleha para,
            <br />
            Colombo 07,
            <br />
            Sri Lanka
          </span>
          <br />
          <h5 className="footer--subHeading">Hotline</h5>
          <span className="footer--span">+94 71 600 2345</span>
          <br />
          <h5 className="footer--subHeading">Email</h5>
          <span className="footer--span">info@slsafaris.com</span>
          <br />
          <br />
        </div>
        <div className="footer--line"></div>
        <span className="footer--copyright">2023 Evotech @ ALL Rights Reserved</span>
      </div>
    </>
  );
}

export default Footer;
