import React, { useState } from "react";
import Header from "../../components/header/header";
import "../../pages/home/home.css";
import Hero from "../../components/hero/hero";
import HeroImg from "../../_resources/img/homeHero.png";
import welcomeImg from "../../_resources/img/homeWelcome.png";
import Img1 from "../../_resources/img/homSec2Img1.png";
import Img2 from "../../_resources/img/homSec2Img2.png";
import Img3 from "../../_resources/img/Sec3Img1.png";
import Img4 from "../../_resources/img/Sec3Img2.png";
import Check from "../../_resources/img/Check.png";
import { Link } from "react-router-dom";
import Card from "../../components/card/card";
import exp from "../../data/experiences";
import dest from "../../data/destinations";
import tr from "../../data/tours";
import Gallery from "../../components/gallery/gallery";
import Review from "../../components/review/reviewCard";
import Footer from "../../components/footer/footer";

export default function Home() {
  const indexes = [
    Math.floor(Math.random() * exp.length),
    Math.floor(Math.random() * exp.length),
    Math.floor(Math.random() * exp.length),
  ];
  const exps = [];

  for (let i = 0; i < 3; i++) {
    let experience = exp[Math.floor(Math.random() * exp.length)];
    exps.push(<Card id={experience.id}
      title={experience.title}
      desc={experience.desc}
      img={`/_resources/experiences/${experience.img}`}
      readMore={experience.readmore}
    />);
  }

  const dests = [];

  for (let i = 0; i < 3; i++) {
    let destination = dest[Math.floor(Math.random() * dest.length)];
    dests.push(<Card id={destination.id}
      title={destination.title}
      desc={destination.desc}
      img={`/_resources/destinations/${destination.img}`}
      readMore={destination.readmore ? `/destination/${destination.id}` : false}
    />);
  }

  const tours = [];

  for (let i = 0; i < 3; i++) {
    let tour = tr[Math.floor(Math.random() * tr.length)];
    tours.push(<Card id={tour.id}
      title={tour.title}
      desc={tour.desc}
      img={`/_resources/tours/${tour.img}`}
      readMore={tour.readmore}
    />);
  }
  return (
    <>
      <Hero
        img={HeroImg}
        heading={"Uncover the Magic of Sri Lanka with Us"}
        subHeading={"From Lush Jungles to Golden Shores"}
      />
      <section className="home--sec2">
        <div className="home--sec2div2">
          <h3 className="home--welcomeTitle">Welcome to Sri Lanka,</h3>
          <p className="home--welcomePara">
            where paradise unfolds in a single island. Explore pristine beaches,
            ancient temples, and vibrant markets brimming with culture. Indulge
            in the exotic flavors of Sri Lankan cuisine, from spicy curries to
            fresh seafood. Whether trekking through tea plantations or
            encountering wildlife in national parks, every moment promises
            adventure. Come, uncover the charms of this diverse gem in the
            Indian Ocean.
          </p>
          <div className="home--welcomeImgs">
            <div
              className="home--welcomeImg"
              style={{ backgroundImage: `url(${Img2})` }}
            ></div>
            <div
              className="home--welcomeImg"
              style={{ backgroundImage: `url(${Img1})` }}
            ></div>
          </div>
        </div>
        <div className="home--sec2div1">
          <img src={welcomeImg} className="home--welcome" />
        </div>
      </section>
      <section className="home--sec3">
        <div className="home--sec3ContentDiv">
          <h1 className="home--sec3Title">PLAN YOUR JOURNEY WITH US</h1>
          <p className="home--sec3Para">
            Explore our diverse selection of tailored experiences across
            enchanting destinations. Our expert planners are here to guide you
            through customizable itineraries featuring cultural tours,
            adventurous expeditions, and more. Benefit from our local insights
            and personalized recommendations for a seamless travel experience.
            Contact us today to begin crafting your unforgettable journey with
            confidence and ease.
          </p>
          <ul className="home--sec3List">
            <li className="home--sec3ListItem">
              <img src={Check} className="home--sec3ListItemImg" />
              <span className="home--sec3ListItemText">
                Tailored experiences just for you
              </span>
            </li>

            <li className="home--sec3ListItem">
              <img src={Check} className="home--sec3ListItemImg" />
              <span className="home--sec3ListItemText">
                From itinerary planning to local insights
              </span>
            </li>
            <li className="home--sec3ListItem">
              <img src={Check} className="home--sec3ListItemImg" />
              <span className="home--sec3ListItemText">
                Your journey, personalized and perfected
              </span>
            </li>
          </ul>
          <Link to={"./contact"} className="home--sec3Btn">
            Contact Now!
          </Link>
        </div>
        <div className="home--sec3ImgDiv">
          <img src={Img3} className="home--sec3Img1" loading="lazy" />
          <img src={Img4} className="home--sec3Img2" loading="lazy" />
        </div>
      </section>

      <section className="home--sec4">
        <h2 className="home--sec4Title">EXPERIENCES</h2>
        <span className="home--sec4Desc">
          Discover unforgettable adventures and unique cultural experiences in
          Sri Lanka
        </span>
        <div className="home--sec4CardWrapper">
          {exps}
        </div>
        <Link to={"./experiences"} className="home--moreBtn">
          More Experiences
        </Link>
      </section>
      <section className="home--sec4">
        <h2 className="home--sec4Title">Destinations</h2>
        <span className="home--sec4Desc">
          Discover unforgettable adventures and unique cultural experiences in
          Sri Lanka
        </span>
        <div className="home--sec4CardWrapper">
          {dests}
        </div>
        <Link to={"./experiences"} className="home--moreBtn">
          More Destinations
        </Link>
      </section>
      <section className="home--sec4">
        <h2 className="home--sec4Title">Tours</h2>
        <span className="home--sec4Desc">
          Discover unforgettable adventures and unique cultural experiences in
          Sri Lanka
        </span>
        <div className="home--sec4CardWrapper">
          {tours}
        </div>
        <Link to={"./experiences"} className="home--moreBtn">
          More Tours
        </Link>
      </section>
      <section className="home--sec5">
        <h2 className="home--sec5Title">Gallery</h2>
        <Gallery />
      </section>
      <section className="home--sec6">
        <div className="home--sec6Content">
          <h2 className="home--sec5Title">Our testimonials</h2>
          <div className="home--reviewRow">
            <Review />
            <Review />
            <Review />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
