const tours = [
  {
      id: 1,
      title: "Courtyards & Verandahs",
      desc: `
        Discover the rich architectural heritage of Sri Lanka with our Courtyards & Verandahs tour. Explore traditional Sri Lankan homes, featuring intricate designs, spacious courtyards, and charming verandahs that reflect the island’s cultural essence and architectural brilliance.
      `,
      img: "courtyards_verandahs.png",
      readmore: false
  },
  {
      id: 2,
      title: "Cycling in Sri Lanka Tour",
      desc: `
        Embark on an exhilarating cycling journey through Sri Lanka’s diverse landscapes. From lush tea plantations and verdant hills to stunning coastal roads, experience the island’s natural beauty and vibrant culture on two wheels.
      `,
      img: "cycling_sri_lanka.png",
      readmore: false
  },
  {
      id: 3,
      title: "Everlasting Summer in Lanka",
      desc: `
        Revel in the eternal warmth of Sri Lanka with our Everlasting Summer tour. Bask in the island’s sun-kissed beaches, enjoy tropical breezes, and indulge in the endless summer atmosphere that defines Sri Lanka’s climate and lifestyle.
      `,
      img: "everlasting_summer.png",
      readmore: false
  },
  {
      id: 4,
      title: "Experiential East",
      desc: `
        Explore the vibrant Eastern Coast of Sri Lanka with our Experiential East tour. Discover pristine beaches, historic sites, and local culture as you experience the unique charm and beauty of Sri Lanka’s eastern region.
      `,
      img: "experiential_east.png",
      readmore: false
  },
  {
      id: 5,
      title: "Fabulous Sri Lanka",
      desc: `
        Experience the island paradise of Sri Lanka effortlessly with our Fabulous Sri Lanka tour. Enjoy a well-rounded glimpse of the island’s stunning landscapes, rich culture, and diverse attractions, all designed to showcase Sri Lanka’s beauty in the easiest and most enjoyable way.
      `,
      img: "fabulous_sri_lanka.png",
      readmore: false
  },
  {
      id: 6,
      title: "Love Songs of Ceylon",
      desc: `
        Indulge in a romantic journey with our Love Songs of Ceylon tour. Immerse yourself in the enchanting melodies and poetic heritage of Sri Lanka’s love songs while exploring the island’s most romantic spots and experiences.
      `,
      img: "love_songs_ceylon.png",
      readmore: false
  },
  {
      id: 7,
      title: "Meditation & Yoga",
      desc: `
        Enhance your mental well-being with our Meditation & Yoga tour. Engage in mindful practices and yoga sessions amidst Sri Lanka’s serene landscapes, designed to promote relaxation, inner peace, and mental clarity.
      `,
      img: "meditation_yoga.png",
      readmore: false
  },
  {
      id: 8,
      title: "Ramayana Tour",
      desc: `
        Explore the legendary tales of the Ramayana with our Ramayana Tour. Visit significant sites connected to the ancient epic, delve into the rich mythology of Sri Lanka, and uncover the stories that have shaped the island's cultural and spiritual heritage.
      `,
      img: "ramayana_tour.png",
      readmore: false
  },
  {
      id: 9,
      title: "Scenic Sri Lanka",
      desc: `
        Get a comprehensive glimpse of Sri Lanka with our Scenic Sri Lanka tour. This quick snapshot captures the essence of the island’s diverse landscapes, cultural highlights, and natural beauty, offering a well-rounded view of what makes Sri Lanka truly remarkable.
      `,
      img: "scenic_sri_lanka.png",
      readmore: false
  },
  {
      id: 10,
      title: "Sri Lanka’s Big Five Game",
      desc: `
        Discover Sri Lanka’s Big Five Game with our unique tour, featuring the island's impressive big cats. Witness the elusive leopard, Sri Lankan sloth bear, and other magnificent predators in their natural habitats, showcasing Sri Lanka’s remarkable wildlife outside of Africa.
      `,
      img: "big_five_game.png",
      readmore: false
  },
  {
      id: 11,
      title: "Wrecks & Reefs",
      desc: `
        Dive into the underwater world of Sri Lanka with our Wrecks & Reefs tour. Explore fascinating shipwrecks and vibrant coral reefs, revealing the rich marine life and submerged history that make Sri Lanka a premier diving destination.
      `,
      img: "wrecks_reefs.png",
      readmore: false
  }
];

export default tours;
