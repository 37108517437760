const dest = [
  {
    id: 1,
    title: "Anuradhapura",
    desc: `
        Explore the ancient city of Anuradhapura, home to well-preserved ruins of Sri Lanka’s first capital. Discover its sacred temples, monasteries, and historical landmarks.
      `,
    longDesc: `
        Anuradhapura is one of the ancient capitals of Sri Lanka, renowned for its rich archaeological and cultural heritage. This UNESCO World Heritage site features the sacred Bodhi Tree, believed to be the oldest historically authenticated tree in the world. Visitors can marvel at the impressive stupas, ancient monasteries, and intricate carvings that reflect the city’s historical significance. Anuradhapura offers a journey back in time, providing a deep insight into the early civilizations of Sri Lanka.
      `,
    img: "anuradhapura.png",
    readmore: true,
  },
  {
    id: 2,
    title: "Arugam Bay",
    desc: `
        Known as Sri Lanka's surf capital, Arugam Bay offers world-class waves and a laid-back beach vibe. Relax on sandy shores, explore local seafood cuisine, and enjoy vibrant nightlife.
      `,
    longDesc: `
        Arugam Bay, located on the southeast coast of Sri Lanka, is a paradise for surfers and beach lovers alike. With its consistent waves, it attracts surfers from around the globe. Beyond surfing, visitors can explore the nearby Kumana National Park, famous for its diverse birdlife and wildlife. The town itself boasts a relaxed atmosphere, with beachside cafes, yoga retreats, and a welcoming local community. Whether you're riding the waves or soaking up the sun, Arugam Bay promises an unforgettable experience.
      `,
    img: "arugamBay.png",
    readmore: true,
  },
  {
    id: 3,
    title: "Bentota",
    desc: `
        Enjoy the tranquil beaches of Bentota, known for its luxury resorts and water sports. Experience the serene Bentota River and the town’s lush landscapes.
      `,
    longDesc: `
        Bentota is a picturesque coastal town on the southwestern coast of Sri Lanka, famous for its pristine beaches, luxury resorts, and vibrant water sports scene. Visitors can indulge in activities such as windsurfing, jet-skiing, and snorkeling. The serene Bentota River offers opportunities for boat safaris, where you can explore mangroves and spot diverse wildlife. Bentota also boasts beautiful gardens, including the famous Brief Garden, designed by renowned Sri Lankan artist Bevis Bawa. Whether seeking adventure or relaxation, Bentota provides a perfect escape.
      `,
    img: "bentota.png",
    readmore: true,
  },
  {
    id: 4,
    title: "Colombo",
    desc: `
        Discover the vibrant city of Colombo, Sri Lanka's commercial capital. Explore its bustling markets, historic sites, and modern attractions.
      `,
    longDesc: `
        Colombo, the commercial capital of Sri Lanka, is a dynamic city that offers a blend of modernity and history. Visitors can explore a variety of attractions, including the historic Colombo Fort area, the bustling Pettah Market, and the serene Galle Face Green promenade. The city boasts a diverse culinary scene, vibrant nightlife, and numerous shopping destinations, from high-end malls to local boutiques. Colombo is also home to significant cultural landmarks, such as the Gangaramaya Temple and the National Museum. As a gateway to the rest of the island, Colombo provides a lively and multifaceted experience.
      `,
    img: "colombo.png",
    readmore: true,
  },
  {
    id: 5,
    title: "Dambulla",
    desc: `
        Visit the Dambulla Cave Temple, a UNESCO World Heritage site. Explore the impressive rock formations and ancient Buddhist murals within the caves.
      `,
    longDesc: `
        Dambulla is renowned for its stunning cave temple complex, also known as the Golden Temple of Dambulla. This UNESCO World Heritage site consists of five caves adorned with intricate statues and murals depicting the life of Buddha. The temple complex is perched on a massive rock, offering panoramic views of the surrounding plains. Besides the temple, visitors can explore the nearby Dambulla Royal Cave Temple and Museum, which provides further insights into the region’s rich history and culture. Dambulla is a spiritual and cultural treasure that offers a glimpse into Sri Lanka’s ancient heritage.
      `,
    img: "dambulla.png",
    readmore: true,
  },
  {
    id: 6,
    title: "Ella",
    desc: `
        Revel in the lush greenery and rolling hills of Ella. Visit the iconic Nine Arches Bridge and hike to Little Adam's Peak for stunning vistas and serene surroundings.
      `,
    longDesc: `
        Ella is a charming hill country village surrounded by lush greenery, tea plantations, and rolling hills. It is famous for its scenic train rides, particularly the route from Kandy to Ella. Highlights include the Nine Arches Bridge, an architectural marvel, and Little Adam's Peak, which offers breathtaking panoramic views after a relatively easy hike. Ella also boasts picturesque waterfalls like Ravana Falls and the serene ambiance of Ella Rock. The town is perfect for nature lovers and those seeking a tranquil escape amidst Sri Lanka’s verdant landscapes.
      `,
    img: "ella.png",
    readmore: true,
  },
  {
    id: 7,
    title: "Galle",
    desc: `
        Stroll through the cobblestone streets of Galle Fort, a UNESCO World Heritage site. Enjoy the mix of colonial architecture, charming cafes, and breathtaking ocean views.
      `,
    longDesc: `
        Galle is a historic city on Sri Lanka’s southwestern coast, known for its well-preserved Galle Fort, a UNESCO World Heritage site. The fort's cobblestone streets are lined with colonial buildings, quaint boutiques, and cozy cafes. Visitors can explore the fort's ramparts for panoramic ocean views and visit historic landmarks such as the Dutch Reformed Church and the Galle Lighthouse. Galle’s vibrant culture is reflected in its art galleries, traditional crafts, and lively markets. The city offers a captivating blend of historical charm and modern vibrancy.
      `,
    img: "galle.png",
    readmore: true,
  },
  {
    id: 8,
    title: "Habarana",
    desc: `
        Discover Habarana, a gateway to Sri Lanka’s cultural triangle. Explore nearby ancient ruins, enjoy wildlife safaris, and experience the local village life.
      `,
    longDesc: `
        Habarana is a central hub in Sri Lanka’s cultural triangle, offering easy access to several key historical sites and natural attractions. It serves as a gateway to the ancient cities of Sigiriya and Polonnaruwa, where visitors can explore impressive ruins and archaeological sites. Habarana is also known for its proximity to national parks like Minneriya, where wildlife enthusiasts can embark on exciting safari tours to spot elephants and other exotic animals. The town itself provides a glimpse into traditional Sri Lankan village life, with opportunities to experience local culture and cuisine.
      `,
    img: "habarana.png",
    readmore: true,
  },
  {
    id: 9,
    title: "Hatton",
    desc: `
        Explore the scenic beauty of Hatton, nestled in Sri Lanka's tea country. Enjoy breathtaking views, visit tea plantations, and experience the serenity of lush green hills.
      `,
    longDesc: `
        Hatton is a charming town situated in Sri Lanka’s tea-growing region, known for its stunning landscapes and lush green hills. The area is renowned for its tea plantations, where visitors can take guided tours to learn about the tea production process and sample high-quality Ceylon tea. Hatton offers picturesque views of the surrounding mountains and valleys, making it an ideal destination for nature enthusiasts and those seeking a peaceful retreat. The nearby Adams Peak (Sri Pada) is a popular pilgrimage site and hiking destination, providing panoramic views from the summit.
      `,
    img: "hatton.png",
    readmore: true,
  },
  {
    id: 10,
    title: "Hikkaduwa",
    desc: `
        Enjoy the vibrant marine life and sandy beaches of Hikkaduwa. Explore the coral reefs, indulge in water sports, and experience the lively beachside atmosphere.
      `,
    longDesc: `
        Hikkaduwa is a lively coastal town located on Sri Lanka’s southwestern coast, renowned for its beautiful beaches and vibrant marine life. The Hikkaduwa Coral Reef, a protected marine sanctuary, offers excellent snorkeling and diving opportunities to explore colorful coral formations and diverse fish species. The town's beach is lined with restaurants, bars, and shops, creating a bustling and energetic atmosphere. Visitors can also enjoy activities such as surfing, boat rides, and exploring nearby cultural sites. Hikkaduwa is perfect for those seeking both adventure and relaxation by the sea.
      `,
    img: "hikkaduwa.png",
    readmore: true,
  },
  {
    id: 11,
    title: "Jaffna",
    desc: `
        Discover the unique culture and heritage of Jaffna. Visit ancient temples, explore vibrant markets, and experience the distinct Tamil influence in this northern city.
      `,
    longDesc: `
        Jaffna, located in the northern part of Sri Lanka, is known for its rich cultural heritage and Tamil influences. The city offers a unique blend of historical and cultural experiences, with ancient temples like the Nallur Kandaswamy Kovil and the Jaffna Fort reflecting its past. Visitors can explore bustling markets, savor local Tamil cuisine, and enjoy the distinct atmosphere of the region. Jaffna also features scenic attractions such as the beautiful beaches of Casuarina and the historic Keerimalai Springs. The city provides a fascinating insight into Sri Lanka’s diverse cultural tapestry.
      `,
    img: "jaffna.png",
    readmore: true,
  },
  {
    id: 12,
    title: "Kalpitiya",
    desc: `
        Experience the serene beauty of Kalpitiya, a hidden gem known for its tranquil beaches and kite surfing opportunities. Explore the nearby lagoon and enjoy a peaceful retreat.
      `,
    longDesc: `
        Kalpitiya is a picturesque coastal area located on the northwestern coast of Sri Lanka. Known for its serene and unspoiled beaches, Kalpitiya offers a tranquil escape from the hustle and bustle of city life. The area is famous for its kite surfing opportunities, with ideal wind conditions and clear waters. Visitors can also explore the Kalpitiya Lagoon, which is home to diverse bird species and offers boat safaris. The region’s peaceful atmosphere and natural beauty make it a perfect destination for relaxation and outdoor activities.
      `,
    img: "kalpitiya.png",
    readmore: true,
  },
  {
    id: 13,
    title: "Kandy",
    desc: `
        Immerse yourself in the cultural richness of Kandy. Visit the sacred Temple of the Tooth, stroll through the beautiful Royal Botanical Gardens, and experience traditional dance performances.
      `,
    longDesc: `
        Kandy, located in Sri Lanka’s central highlands, is a city renowned for its cultural and historical significance. It is home to the Temple of the Tooth Relic, one of the most sacred Buddhist sites in the world. Visitors can explore the Royal Botanical Gardens, which showcase a stunning array of tropical flora, and experience traditional Kandyan dance performances that highlight the region’s cultural heritage. Kandy also features picturesque lakes, vibrant markets, and scenic viewpoints. As a former royal capital, it offers a glimpse into Sri Lanka’s rich and diverse history.
      `,
    img: "kandy.png",
    readmore: true,
  },
  {
    id: 14,
    title: "Kitulgala",
    desc: `
        Discover Kitulgala, a haven for adventure seekers. Enjoy white-water rafting on the Kelani River, explore lush rainforests, and spot exotic wildlife in the surrounding area.
      `,
    longDesc: `
        Kitulgala is an adventurous destination located in Sri Lanka’s rainforest region. Known for its thrilling white-water rafting experiences on the Kelani River, it attracts adventure enthusiasts from around the world. The surrounding area is rich in biodiversity, with lush rainforests and opportunities for bird watching and wildlife spotting. Kitulgala is also home to the famous Belilena Cave, which has archaeological significance. Whether you’re seeking adrenaline-pumping activities or serene natural beauty, Kitulgala offers a diverse range of experiences.
      `,
    img: "kitulgala.png",
    readmore: true,
  },
  {
    id: 15,
    title: "Mirissa",
    desc: `
        Relax in the coastal town of Mirissa, known for its stunning beaches and whale watching opportunities. Enjoy the laid-back atmosphere and vibrant nightlife.
      `,
    longDesc: `
        Mirissa is a charming beach town on Sri Lanka’s southern coast, renowned for its beautiful beaches and vibrant marine life. It is a prime location for whale watching, with seasonal tours offering the chance to see blue whales and dolphins. The town’s relaxed atmosphere is complemented by a range of beachfront cafes and restaurants, where visitors can enjoy fresh seafood and stunning ocean views. Mirissa also offers opportunities for snorkeling, surfing, and exploring nearby attractions such as the Weligama Bay and the Galle Fort. It’s an ideal destination for both relaxation and adventure.
      `,
    img: "mirissa.png",
    readmore: true,
  },
  {
    id: 16,
    title: "Negombo",
    desc: `
        Explore the vibrant fishing town of Negombo, famous for its sandy beaches and bustling fish markets. Experience the blend of colonial history and local culture.
      `,
    longDesc: `
        Negombo is a lively coastal town located near Colombo, known for its beautiful beaches and vibrant fishing industry. The town features a blend of colonial architecture and local culture, with attractions such as the Negombo Fish Market, where visitors can experience the hustle and bustle of daily life. Negombo's sandy beaches offer opportunities for relaxation and water sports, while the nearby St. Mary’s Church and Dutch Fort reflect the region’s historical influences. The town’s culinary scene is highlighted by fresh seafood and traditional Sri Lankan dishes.
      `,
    img: "negombo.png",
    readmore: true,
  },
  {
    id: 17,
    title: "Nuwara Eliya",
    desc: `
        Escape to Nuwara Eliya, a picturesque hill station known for its tea plantations and colonial charm. Enjoy the cool climate, scenic views, and historic landmarks.
      `,
    longDesc: `
        Nuwara Eliya, often referred to as “Little England,” is a charming hill station located in Sri Lanka’s central highlands. Known for its cool climate, lush tea plantations, and colonial-era architecture, it offers a refreshing contrast to the island’s coastal areas. Visitors can explore the scenic landscapes, visit tea factories, and enjoy activities such as boating on Lake Gregory. The town’s attractions include the historic Nuwara Eliya Golf Club and the picturesque Hakgala Botanical Gardens. Nuwara Eliya is perfect for those seeking a tranquil retreat amidst stunning natural beauty.
      `,
    img: "nuwaraEliya.png",
    readmore: true,
  },
  {
    id: 18,
    title: "Passikudah",
    desc: `
        Relax in Passikudah, a tranquil beach destination known for its shallow turquoise waters and pristine sandy shores. Ideal for swimming and leisurely beach days.
      `,
    longDesc: `
        Passikudah is a serene coastal area located on Sri Lanka’s eastern coast, renowned for its beautiful beaches and calm, shallow waters. The area’s pristine sandy shores and crystal-clear waters make it an ideal destination for swimming and leisurely relaxation. Passikudah offers a peaceful escape with opportunities for snorkeling and exploring nearby attractions such as the Batticaloa Lagoon. The region’s laid-back atmosphere and natural beauty provide a perfect setting for unwinding and enjoying a tranquil beach experience.
      `,
    img: "passikudah.png",
    readmore: true,
  },
  {
    id: 19,
    title: "Polonnaruwa",
    desc: `
        Explore the ancient city of Polonnaruwa, a UNESCO World Heritage site filled with impressive ruins and historical landmarks. Discover its well-preserved temples, palaces, and statues.
      `,
    longDesc: `
        Polonnaruwa, the second ancient capital of Sri Lanka, is a UNESCO World Heritage site renowned for its well-preserved ruins and archaeological treasures. Visitors can explore the ancient Royal Palace, the Gal Vihara rock temple with its stunning Buddha statues, and the remarkable Parakrama Samudra, an ancient reservoir. The city’s historical significance is reflected in its intricate carvings, ancient temples, and grand monuments, offering a fascinating glimpse into Sri Lanka’s medieval history and culture.
      `,
    img: "polonnaruwa.png",
    readmore: true,
  },
  {
    id: 20,
    title: "Sigiriya",
    desc: `
        Visit Sigiriya, the iconic Lion's Rock, a UNESCO World Heritage site known for its stunning rock fortress and ancient frescoes. Climb to the top for panoramic views and explore its historical significance.
      `,
    longDesc: `
        Sigiriya, also known as Lion's Rock, is a UNESCO World Heritage site renowned for its striking rock fortress that rises dramatically from the surrounding landscape. Built during the 5th century by King Kassapa, the site features impressive frescoes, elaborate water gardens, and the remains of an ancient royal palace at its summit. The ascent to the top offers breathtaking panoramic views of the surrounding countryside. Sigiriya is an architectural and historical marvel, providing insight into Sri Lanka’s ancient engineering and artistry.
      `,
    img: "sigiriya.png",
    readmore: true,
  },
  {
    id: 21,
    title: "Sinharaja",
    desc: `
        Discover Sinharaja Forest Reserve, a UNESCO World Heritage site and one of Sri Lanka’s last remaining rainforests. Enjoy trekking through lush greenery and observe its unique biodiversity.
      `,
    longDesc: `
        Sinharaja Forest Reserve, a UNESCO World Heritage site, is a pristine rainforest located in the southwest of Sri Lanka. It is renowned for its incredible biodiversity, housing numerous endemic plant and animal species. Visitors can embark on guided treks through the lush forest, encountering a variety of birds, butterflies, and rare flora. The reserve offers a unique opportunity to experience one of the last remaining lowland rainforests in Sri Lanka, with its rich ecosystems and stunning natural beauty.
      `,
    img: "sinharaja.png",
    readmore: true,
  },
  {
    id: 22,
    title: "Tangalle",
    desc: `
        Relax in Tangalle, a coastal town known for its beautiful beaches and tranquil atmosphere. Enjoy the serene environment, explore local seafood, and visit nearby natural attractions.
      `,
    longDesc: `
        Tangalle, located on Sri Lanka’s southern coast, is a picturesque town renowned for its pristine beaches and relaxed ambiance. The town offers a perfect escape with its golden sandy shores and clear waters, ideal for swimming and sunbathing. Visitors can explore local seafood restaurants, enjoy the scenic beauty of Tangalle’s natural surroundings, and visit nearby attractions such as the Rekawa Turtle Conservation Project and the Kalametiya Bird Sanctuary. Tangalle provides a peaceful retreat and a chance to experience the charm of Sri Lanka’s southern coastline.
      `,
    img: "tangalle.png",
    readmore: true,
  },
  {
    id: 23,
    title: "Trincomalee",
    desc: `
        Explore Trincomalee, a historic port city with stunning beaches and cultural landmarks. Visit the Koneswaram Temple, relax on the beautiful Nilaveli Beach, and enjoy the city's rich history.
      `,
    longDesc: `
        Trincomalee, situated on Sri Lanka’s northeastern coast, is a vibrant port city known for its historical significance and natural beauty. Visitors can explore the Koneswaram Temple, an ancient Hindu shrine with panoramic views of the harbor. Trincomalee is also famous for its pristine beaches, including Nilaveli and Uppuveli, which offer excellent opportunities for relaxation and water activities. The city’s rich history is reflected in sites like Fort Frederick and the Maritime and Naval History Museum. Trincomalee provides a mix of cultural experiences and seaside relaxation.
      `,
    img: "trincomalee.png",
    readmore: true,
  },
  {
    id: 24,
    title: "Udawalawe",
    desc: `
        Discover Udawalawe National Park, a popular wildlife sanctuary known for its large elephant population. Enjoy exciting safaris and explore the park's diverse flora and fauna.
      `,
    longDesc: `
        Udawalawe National Park, located in the southern part of Sri Lanka, is a renowned wildlife sanctuary celebrated for its large population of Asian elephants. The park offers thrilling safari experiences where visitors can observe these majestic creatures in their natural habitat. Besides elephants, Udawalawe is home to a variety of wildlife, including deer, leopards, and numerous bird species. The park’s diverse landscapes, from open grasslands to dense forest areas, provide ample opportunities for wildlife photography and nature exploration. Udawalawe is a must-visit destination for wildlife enthusiasts and nature lovers.
      `,
    img: "udawalawe.png",
    readmore: true,
  },
  {
    id: 25,
    title: "Unawatuna",
    desc: `
        Enjoy the vibrant beach town of Unawatuna, known for its golden sands and lively atmosphere. Relax on the beach, explore local eateries, and experience the region's vibrant nightlife.
      `,
    longDesc: `
        Unawatuna, located on Sri Lanka’s southern coast, is a popular beach destination known for its beautiful golden sands and lively ambiance. The beach town offers a range of activities including sunbathing, swimming, and snorkeling in the clear turquoise waters. Unawatuna's beachside cafes and restaurants serve delicious seafood and local dishes, creating a vibrant dining scene. The area also features attractions like the Japanese Peace Pagoda and the nearby Galle Fort. With its mix of relaxation and activity, Unawatuna is a favorite spot for both tourists and locals.
      `,
    img: "unawatuna.png",
    readmore: true,
  },
  {
    id: 26,
    title: "Weligama",
    desc: `
        Discover Weligama, a charming coastal town known for its surf-friendly waves and stunning bay. Enjoy beach activities, surf lessons, and explore the local culture.
      `,
    longDesc: `
        Weligama, located on Sri Lanka’s southern coast, is a renowned destination for surfers and beachgoers. The town's bay offers ideal conditions for surfing, with waves suitable for both beginners and experienced surfers. Beyond the surf, Weligama features beautiful sandy beaches and opportunities for whale watching tours. The local culture is reflected in its vibrant markets and seafood restaurants. Visitors can also explore nearby attractions such as the Mirissa Whale Watching and the historical town of Matara. Weligama is a perfect blend of relaxation, adventure, and cultural experiences.
      `,
    img: "weligama.png",
    readmore: true,
  },
  {
    id: 27,
    title: "Wellawaya",
    desc: `
        Experience Wellawaya, a serene town surrounded by lush landscapes and scenic beauty. Visit nearby attractions like the Buduruwagala Rock Temple and enjoy the peaceful environment.
      `,
    longDesc: `
        Wellawaya, located in Sri Lanka’s Uva Province, is a tranquil town surrounded by picturesque landscapes and natural beauty. The area is known for its lush greenery and is a gateway to several scenic attractions. Key highlights include the Buduruwagala Rock Temple, which features impressive ancient carvings and statues. Visitors can also explore the nearby Ella Gap and Ravana Falls for stunning views and outdoor activities. Wellawaya’s serene environment provides a peaceful retreat from the bustling city life, making it an ideal destination for relaxation and nature exploration.
      `,
    img: "wellawaya.png",
    readmore: true,
  },
  {
    id: 28,
    title: "Wilpattu",
    desc: `
        Explore Wilpattu National Park, renowned for its diverse wildlife and scenic landscapes. Enjoy safari adventures and spot unique species in their natural habitat.
      `,
    longDesc: `
        Wilpattu National Park, located in Sri Lanka's northwest, is one of the island’s largest and oldest national parks. It is famous for its unique “villus” (natural lakes) and diverse wildlife. Visitors can embark on safari adventures to spot a variety of animals, including leopards, sloth bears, and herds of elephants. The park’s varied landscapes, including dense jungle and open plains, offer a rich wildlife experience. Wilpattu is less crowded compared to other parks, providing a more serene and intimate encounter with nature.
      `,
    img: "wilpattu.png",
    readmore: true,
  },
  {
    id: 29,
    title: "Yala",
    desc: `
        Discover Yala National Park, known for its rich wildlife and stunning landscapes. Experience thrilling safaris and view a range of animals, including the elusive leopard.
      `,
    longDesc: `
        Yala National Park, situated in the southeast of Sri Lanka, is one of the country’s most popular wildlife reserves. The park is renowned for its high density of leopards, and visitors can embark on exciting safari drives to spot these elusive big cats along with other wildlife such as elephants, crocodiles, and various bird species. Yala’s diverse landscapes include grasslands, forested areas, and coastal regions, offering a range of habitats for its wildlife. The park’s natural beauty and rich biodiversity make it a must-visit destination for nature and wildlife enthusiasts.
      `,
    img: "yala.png",
    readmore: true,
  }
];

export default dest;