const exp = [
  {
    id: 1,
    title: "Dolphin Watching",
    desc: `
            Experience the thrill of dolphin watching in Sri Lanka’s shimmering coastal waters, where you can observe playful dolphins in their natural habitat.
        `,
    img: "dolphin.png",
    readmore: false,
    destination: 1
  },
  {
    id: 2,
    title: "Sri Maha Bodhi",
    desc: `
            Visit the Sri Maha Bodhi, one of the most sacred Buddhist sites in Sri Lanka, featuring a revered tree believed to be a sapling from the original Bodhi tree under which Buddha attained enlightenment.
        `,
    img: "sri-maha-bodhi.png",
    readmore: false,
    destination: 1
  },
  {
    id: 3,
    title: "Ruwanweli Maha Seya",
    desc: `
            Explore Ruwanweli Maha Seya, an ancient and iconic stupa in Anuradhapura, known for its historical significance and impressive architecture.
        `,
    img: "ruwanweli-maha-seya.png",
    readmore: false,
    destination: 1
  },
  {
    id: 4,
    title: "Mihintale",
    desc: `
            Discover Mihintale, a significant pilgrimage site where Buddhism was introduced to Sri Lanka, featuring ancient ruins and stunning views from the top of the hill.
        `,
    img: "mihintale.png",
    readmore: false,
    destination: 1
  },
  {
    id: 5,
    title: "Kumana National Park",
    desc: `
            Explore Kumana National Park, a birdwatcher's paradise with a diverse range of bird species, including migratory birds, set amidst beautiful wetlands and forested areas.
        `,
    img: "kumana-national-park.png",
    readmore: false,
    destination: 2
  },
  {
    id: 6,
    title: "Learn Surfing",
    desc: `
            Take a surfing lesson and learn the basics of riding the waves in Sri Lanka’s pristine coastal waters, perfect for both beginners and those looking to improve their skills.
        `,
    img: "learn-surfing.png",
    readmore: false,
    destination: 2
  },
  {
    id: 7,
    title: "Kudumbigala Sanctuary",
    desc: `
            Visit Kudumbigala Sanctuary, an ancient Buddhist monastic complex set within a wildlife sanctuary, featuring rock caves, stupas, and serene natural surroundings.
        `,
    img: "kudumbigala-sanctuary.png",
    readmore: false,
    destination: 2
  },
  {
    id: 8,
    title: "Lunuganga Gardens",
    desc: `
            Explore Lunuganga Gardens, a beautiful estate designed by renowned architect Geoffrey Bawa, showcasing lush landscapes, serene water features, and creative garden design.
        `,
    img: "lunuganga-gardens.png",
    readmore: false,
    destination: 3
  },
  {
    id: 9,
    title: "Bentota Lagoon",
    desc: `
            Enjoy the scenic beauty and tranquil environment of Bentota Lagoon, ideal for boat rides, water sports, and relaxation amidst lush greenery and calm waters.
        `,
    img: "bentota-lagoon.png",
    readmore: false,
    destination: 3
  },
  {
    id: 10,
    title: "Kogoda Turtle Hatchery",
    desc: `
            Visit the Kogoda Turtle Hatchery, where you can learn about sea turtle conservation, observe hatchlings, and support efforts to protect these endangered marine creatures.
        `,
    img: "kogoda-turtle-hatchery.png",
    readmore: false,
    destination: 3
  },
  {
    id: 11,
    title: "Independence Square",
    desc: `
            Explore Independence Square, a historic landmark in Colombo commemorating Sri Lanka’s independence, featuring grand architecture and beautifully landscaped gardens.
        `,
    img: "independence-square.png",
    readmore: false,
    destination: 4
  },
  {
    id: 12,
    title: "Colombo National Museum",
    desc: `
            Visit the Colombo National Museum, showcasing a vast collection of artifacts, historical relics, and cultural exhibits representing Sri Lanka’s rich heritage.
        `,
    img: "colombo-national-museum.png",
    readmore: false,
    destination: 4
  },
  {
    id: 13,
    title: "Gangaramaya Temple",
    desc: `
            Discover Gangaramaya Temple, a prominent Buddhist temple in Colombo known for its eclectic architecture, vibrant cultural activities, and rich history.
        `,
    img: "gangaramaya-temple.png",
    readmore: false,
    destination: 4
  },
  {
    id: 14,
    title: "Dambulla Cave Temple",
    desc: `
            Explore Dambulla Cave Temple, a UNESCO World Heritage site featuring a series of ancient cave temples adorned with stunning Buddhist murals and statues.
        `,
    img: "dambulla-cave-temple.png",
    readmore: false,
    destination: 5
  },
  {
    id: 15,
    title: "Hot Air Balloon Ride",
    desc: `
            Experience the thrill of a hot air balloon ride over the scenic landscapes of Sri Lanka, offering breathtaking views of the countryside and wildlife from above.
        `,
    img: "hot-air-balloon-ride.png",
    readmore: false,
    destination: 5
  },
  {
    id: 16,
    title: "Rose Quartz Mountain",
    desc: `
            Visit Rose Quartz Mountain, known for its unique pink quartz formations and stunning panoramic views, offering a rare geological experience in Sri Lanka.
        `,
    img: "rose-quartz-mountain.png",
    readmore: false,
    destination: 5
  },
  {
    id: 17,
    title: "Mini Adam's Peak",
    desc: `
            Hike up Mini Adam's Peak, a scenic trek offering panoramic views of the surrounding tea plantations and mountains, providing a rewarding experience for nature enthusiasts.
        `,
    img: "mini-adams-peak.png",
    readmore: false,
    destination: 6
  },
  {
    id: 18,
    title: "Demodara Bridge",
    desc: `
            Discover Demodara Bridge, an engineering marvel with its unique spiral design, providing stunning views of the surrounding landscape and a fascinating historical landmark.
        `,
    img: "demodara-bridge.png",
    readmore: false,
    destination: 6
  },
  {
    id: 19,
    title: "Ravana Ella Falls",
    desc: `
            Visit Ravana Ella Falls, a picturesque waterfall nestled in the lush greenery of Sri Lanka’s highlands, offering a tranquil spot for relaxation and nature appreciation.
        `,
    img: "ravana-ella-falls.png",
    readmore: false,
    destination: 6
  },
  {
    id: 20,
    title: "Kanneliya Forest",
    desc: `
            Explore Kanneliya Forest, a biodiverse rainforest offering rich flora and fauna, ideal for nature walks and discovering the natural beauty of Sri Lanka’s rainforests.
        `,
    img: "kanneliya-forest.png",
    readmore: false,
    destination: 7
  },
  {
    id: 21,
    title: "Fort Lighthouse",
    desc: `
            Visit the Fort Lighthouse, an iconic landmark offering panoramic views of the surrounding area, and learn about its historical significance and maritime role.
        `,
    img: "fort-lighthouse.png",
    readmore: false,
    destination: 7
  },
  {
    id: 22,
    title: "Dutch Fort",
    desc: `
            Discover the Dutch Fort, a historical site with well-preserved colonial architecture, reflecting Sri Lanka’s colonial past and offering a glimpse into the island’s history.
        `,
    img: "dutch-fort.png",
    readmore: false,
    destination: 7
  },
  {
    id: 23,
    title: "Catamaran Ride",
    desc: `
            Enjoy a relaxing catamaran ride along Sri Lanka’s coast, offering a peaceful way to take in the scenic beauty of the shoreline and crystal-clear waters.
        `,
    img: "catamaran-ride.png",
    readmore: false,
    destination: 8
  },
  {
    id: 24,
    title: "Hurulu Eco Park",
    desc: `
            Explore Hurulu Eco Park, a protected area featuring diverse wildlife and scenic landscapes, providing opportunities for wildlife observation and nature walks.
        `,
    img: "hurulu-eco-park.png",
    readmore: false,
    destination: 8
  },
  {
    id: 25,
    title: "Hiriwadunna Village Walk",
    desc: `
            Take a guided village walk in Hiriwadunna, immersing yourself in local life, experiencing traditional farming practices, and enjoying the peaceful countryside.
        `,
    img: "hiriwadunna-village-walk.png",
    readmore: false,
    destination: 8
  },
  {
    id: 26,
    title: "Adam's Peak Sunrise",
    desc: `
            Witness a breathtaking sunrise from Adam's Peak, a sacred mountain offering stunning panoramic views and a memorable spiritual experience at dawn.
        `,
    img: "adams-peak-sunrise.png",
    readmore: false,
    destination: 9
  },
  {
    id: 27,
    title: "Kayaking on Castlereagh",
    desc: `
            Enjoy a kayaking adventure on Castlereagh Lake, navigating serene waters surrounded by lush landscapes, perfect for a relaxing and scenic water activity.
        `,
    img: "kayaking-castlereagh.png",
    readmore: false,
    destination: 9
  },
  {
    id: 28,
    title: "Warleigh Church",
    desc: `
            Visit Warleigh Church, a historic colonial-era church known for its charming architecture and tranquil setting, providing a peaceful spot for reflection and exploration.
        `,
    img: "warleigh-church.png",
    readmore: false,
    destination: 9
  },
  {
    id: 29,
    title: "Snorkelling and Coral Reefs",
    desc: `
            Discover vibrant coral reefs and marine life with a snorkeling adventure, exploring the rich underwater ecosystems of Sri Lanka’s coastal waters.
        `,
    img: "snorkelling-coral-reefs.png",
    readmore: false,
    destination: 10
  },
  {
    id: 30,
    title: "Glass Bottom Boat Ride",
    desc: `
            Take a glass bottom boat ride to observe underwater life without getting wet, providing a unique view of coral reefs and marine creatures in their natural habitat.
        `,
    img: "glass-bottom-boat-ride.png",
    readmore: false,
    destination: 10
  },
  {
    id: 31,
    title: "Hikkaduwa Turtle Hatchery",
    desc: `
            Visit Hikkaduwa Turtle Hatchery, dedicated to the protection and conservation of sea turtles, where you can learn about their life cycle and see hatchlings up close.
        `,
    img: "hikkaduwa-turtle-hatchery.png",
    readmore: false,
    destination: 10
  },
  {
    id: 32,
    title: "Nallur Temple",
    desc: `
            Explore Nallur Temple, a significant Hindu temple in Jaffna known for its colorful architecture and cultural significance, offering a glimpse into Tamil traditions.
        `,
    img: "nallur-temple.png",
    readmore: false,
    destination: 11
  },
  {
    id: 33,
    title: "Nagadeepa Temple",
    desc: `
            Visit Nagadeepa Temple, a sacred site on Nagadeepa Island with historical and religious importance, featuring beautiful architecture and serene surroundings.
        `,
    img: "nagadeepa-temple.png",
    readmore: false,
    destination: 11
  },
  {
    id: 34,
    title: "Delft Island",
    desc: `
            Discover Delft Island, known for its unique landscapes, wild horses, and historical ruins, offering a peaceful retreat and a glimpse into Sri Lanka’s island life.
        `,
    img: "delft-island.png",
    readmore: false,
    destination: 11
  },
  {
    id: 35,
    title: "Kite / Windsurfing",
    desc: `
            Try kite surfing or windsurfing in Kalpitiya, with ideal conditions for both beginners and experienced riders, enjoying thrilling water sports in a scenic coastal setting.
        `,
    img: "kite-windsurfing.png",
    readmore: false,
    destination: 12
  },
  {
    id: 36,
    title: "Whale and Dolphin Watching",
    desc: `
            Experience the thrill of whale and dolphin watching off Sri Lanka’s coast, where you can spot majestic whales and playful dolphins in their natural habitat.
        `,
    img: "whale-dolphin-watching.png",
    readmore: false,
    destination: 12
  },
  {
    id: 37,
    title: "Kalpitiya Dutch Fort",
    desc: `
            Explore Kalpitiya Dutch Fort, a historic fortification with colonial architecture, offering insights into the region’s history and a scenic view of the surrounding area.
        `,
    img: "kalpitiya-dutch-fort.png",
    readmore: false,
    destination: 12
  },
  {
    id: 38,
    title: "Temple of the Tooth Relic",
    desc: `
            Visit the Temple of the Tooth Relic in Kandy, a sacred Buddhist temple housing the relic of Buddha’s tooth, an important pilgrimage site and cultural landmark.
        `,
    img: "temple-of-the-tooth-relic.png",
    readmore: false,
    destination: 13
  },
  {
    id: 39,
    title: "Peradeniya Botanical Garden",
    desc: `
            Stroll through Peradeniya Botanical Garden, renowned for its diverse plant species, beautifully landscaped gardens, and a relaxing environment for nature lovers.
        `,
    img: "peradeniya-botanical-garden.png",
    readmore: false,
    destination: 13
  },
  {
    id: 40,
    title: "Cultural Dance Show",
    desc: `
            Enjoy a traditional cultural dance show in Kandy, featuring vibrant performances, traditional music, and dances that showcase Sri Lanka’s rich cultural heritage.
        `,
    img: "cultural-dance-show.png",
    readmore: false,
    destination: 13
  },
  {
    id: 41,
    title: "Whitewater Rafting",
    desc: `
            Experience the excitement of whitewater rafting on Sri Lanka’s thrilling rivers, navigating rapids and enjoying an adrenaline-pumping adventure through stunning landscapes.
        `,
    img: "whitewater-rafting.png",
    readmore: false,
    destination: 14
  },
  {
    id: 42,
    title: "Belilena Cave",
    desc: `
            Explore Belilena Cave, an archaeological site with ancient human remains and prehistoric artifacts, offering a fascinating glimpse into Sri Lanka’s early history.
        `,
    img: "belilena-cave.png",
    readmore: false,
    destination: 14
  },
  {
    id: 43,
    title: "Makandawa Rain Forest",
    desc: `
            Discover Makandawa Rain Forest, a lush and biodiverse rainforest offering trails, unique flora and fauna, and a peaceful retreat into nature’s tranquility.
        `,
    img: "makandawa-rain-forest.png",
    readmore: false,
    destination: 14
  },
  {
    id: 44,
    title: "Whale and Dolphin Watching",
    desc: `
            Experience the thrill of whale and dolphin watching off Sri Lanka’s coast, where you can spot majestic whales and playful dolphins in their natural habitat.
        `,
    img: "whale-dolphin-watching.png",
    readmore: false,
    destination: 15
  },
  {
    id: 45,
    title: "Coconut Tree Hill",
    desc: `
            Visit Coconut Tree Hill, a picturesque viewpoint offering panoramic views of the turquoise ocean and palm-fringed coastline, perfect for relaxation and photography.
        `,
    img: "coconut-tree-hill.png",
    readmore: false,
    destination: 15
  },
  {
    id: 46,
    title: "Secret Beach",
    desc: `
            Discover Secret Beach, a hidden gem with secluded golden sands and crystal-clear waters, offering a peaceful retreat away from the crowds.
        `,
    img: "secret-beach.png",
    readmore: false,
    destination: 15
  },
  {
    id: 47,
    title: "Negombo Fish Market",
    desc: `
            Explore Negombo Fish Market, a bustling market offering a variety of fresh seafood, local delicacies, and a vibrant atmosphere reflecting the region’s fishing culture.
        `,
    img: "negombo-fish-market.png",
    readmore: false,
    destination: 16
  },
  {
    id: 48,
    title: "Dutch Fort",
    desc: `
            Discover the Dutch Fort in Negombo, a historic fortification with colonial architecture, providing insights into Sri Lanka’s colonial past and a scenic view of the surroundings.
        `,
    img: "dutch-fort.png",
    readmore: false,
    destination: 16
  },
  {
    id: 49,
    title: "Hamilton Canal",
    desc: `
            Take a boat ride along the Hamilton Canal, enjoying the scenic beauty of the canal, observing local wildlife, and learning about the canal’s historical significance.
        `,
    img: "hamilton-canal.png",
    readmore: false,
    destination: 16
  },
  {
    id: 50,
    title: "Horton Plains",
    desc: `
            Explore Horton Plains, a highland national park featuring stunning landscapes, unique wildlife, and scenic trails, including the famous World’s End viewpoint.
        `,
    img: "horton-plains.png",
    readmore: false,
    destination: 17
  },
  {
    id: 51,
    title: "Hakgala Gardens",
    desc: `
            Stroll through Hakgala Gardens, a beautifully landscaped botanical garden with diverse plant species, offering a peaceful retreat and scenic views.
        `,
    img: "hakgala-gardens.png",
    readmore: false,
    destination: 17
  },
  {
    id: 52,
    title: "Strawberry Fields",
    desc: `
            Visit Strawberry Fields, a charming area known for its strawberry farms, where you can pick fresh strawberries and enjoy the picturesque countryside.
        `,
    img: "strawberry-fields.png",
    readmore: false,
    destination: 17
  },
  {
    id: 53,
    title: "Water Sports",
    desc: `
            Engage in various water sports activities, including jet skiing, windsurfing, and paddleboarding, in Sri Lanka’s scenic coastal areas for an exhilarating experience.
        `,
    img: "water-sports.png",
    readmore: false,
    destination: 18
  },
  {
    id: 54,
    title: "Batticaloa Lighthouse",
    desc: `
            Visit Batticaloa Lighthouse, a historic lighthouse offering panoramic views of the surrounding coastline and a glimpse into the region’s maritime history.
        `,
    img: "batticaloa-lighthouse.png",
    readmore: false,
    destination: 18
  },
  {
    id: 55,
    title: "Singing Fish and Kallady Bridge",
    desc: `
            Discover the unique Singing Fish phenomenon and visit Kallady Bridge, known for its historical significance and picturesque setting, offering a fascinating experience.
        `,
    img: "singing-fish-kallady-bridge.png",
    readmore: false,
    destination: 18
  },
  {
    id: 56,
    title: "Ruins and Temples",
    desc: `
            Explore ancient ruins and temples, showcasing Sri Lanka’s rich historical and cultural heritage, with well-preserved structures and fascinating artifacts.
        `,
    img: "ruins-and-temples.png",
    readmore: false,
    destination: 19
  },
  {
    id: 57,
    title: "Minneriya National Park",
    desc: `
            Visit Minneriya National Park, renowned for its large gatherings of wild elephants, offering a unique wildlife experience and opportunities for wildlife photography.
        `,
    img: "minneriya-national-park.png",
    readmore: false,
    destination: 19
  },
  {
    id: 58,
    title: "Monkey Kingdom",
    desc: `
            Discover Monkey Kingdom, a wildlife sanctuary dedicated to the conservation of monkeys, providing insights into their behavior and habitat.
        `,
    img: "monkey-kingdom.png",
    readmore: false,
    destination: 19
  },
  {
    id: 59,
    title: "Sigiriya Rock",
    desc: `
            Climb Sigiriya Rock, an ancient fortress and UNESCO World Heritage site, known for its impressive frescoes, water gardens, and panoramic views from the summit.
        `,
    img: "sigiriya-rock.png",
    readmore: false,
    destination: 20
  },
  {
    id: 60,
    title: "Pidurangala Rock",
    desc: `
            Hike up Pidurangala Rock, offering stunning views of Sigiriya Rock and the surrounding landscape, providing a rewarding and less crowded alternative to the main attraction.
        `,
    img: "pidurangala-rock.png",
    readmore: false,
    destination: 20
  },
  {
    id: 61,
    title: "Sigiriya Archaeological Museum",
    desc: `
            Visit the Sigiriya Archaeological Museum to learn about the history and excavation of Sigiriya Rock, featuring exhibits on ancient artifacts and historical context.
        `,
    img: "sigiriya-archaeological-museum.png",
    readmore: false,
    destination: 20
  },
  {
    id: 62,
    title: "Forest Trek",
    desc: `
            Embark on a forest trek, exploring Sri Lanka’s diverse ecosystems, encountering unique flora and fauna, and enjoying the serenity of the lush green wilderness.
        `,
    img: "forest-trek.png",
    readmore: false,
    destination: 21
  },
  {
    id: 63,
    title: "Birdwatching Trek",
    desc: `
            Enjoy a birdwatching trek through scenic trails, spotting a variety of bird species in their natural habitat, perfect for nature enthusiasts and wildlife photographers.
        `,
    img: "birdwatching-trek.png",
    readmore: false,
    destination: 21
  },
  {
    id: 64,
    title: "Waterfalls Trek",
    desc: `
            Discover waterfalls on a trekking adventure, exploring lush landscapes and scenic trails leading to stunning cascading waterfalls in Sri Lanka’s picturesque countryside.
        `,
    img: "waterfalls-trek.png",
    readmore: false,
    destination: 21
  },
  {
    id: 65,
    title: "Rekawa Turtle Conservation Project",
    desc: `
            Visit the Rekawa Turtle Conservation Project, dedicated to the protection and conservation of sea turtles, where you can learn about their life cycle and see nesting activities.
        `,
    img: "rekawa-turtle-conservation.png",
    readmore: false,
    destination: 22
  },
  {
    id: 66,
    title: "Hummanaya Blowhole",
    desc: `
            Witness the Hummanaya Blowhole, a natural phenomenon where seawater is forcefully expelled through a blowhole in the rocks, creating a spectacular water spout.
        `,
    img: "hummanaya-blowhole.png",
    readmore: false,
    destination: 22
  },
  {
    id: 67,
    title: "Mulkirigala Temple",
    desc: `
            Explore Mulkirigala Temple, an ancient Buddhist temple complex set on a rock, featuring impressive cave temples, ancient murals, and panoramic views of the surrounding area.
        `,
    img: "mulkirigala-temple.png",
    readmore: false,
    destination: 22
  },
  {
    id: 68,
    title: "Snorkelling at Pigeon Island",
    desc: `
            Enjoy snorkeling at Pigeon Island, renowned for its coral reefs and vibrant marine life, offering a great underwater experience in a protected marine environment.
        `,
    img: "snorkelling-pigeon-island.png",
    readmore: false,
    destination: 23
  },
  {
    id: 69,
    title: "Whale and Dolphin Watching",
    desc: `
            Experience the thrill of whale and dolphin watching off Sri Lanka’s coast, where you can spot majestic whales and playful dolphins in their natural habitat.
        `,
    img: "whale-dolphin-watching.png",
    readmore: false,
    destination: 23
  },
  {
    id: 70,
    title: "Koneswaram Temple",
    desc: `
            Visit Koneswaram Temple, a historic Hindu temple perched on a cliff overlooking the ocean, known for its intricate carvings and panoramic views of Trincomalee Bay.
        `,
    img: "koneswaram-temple.png",
    readmore: false,
    destination: 23
  },
  {
    id: 71,
    title: "Game Drives",
    desc: `
            Experience thrilling game drives in Sri Lanka’s national parks, spotting a variety of wildlife including elephants, leopards, and diverse bird species in their natural habitats.
        `,
    img: "game-drives.png",
    readmore: false,
    destination: 24
  },
  {
    id: 72,
    title: "Elephant Transit Centre",
    desc: `
            Visit the Elephant Transit Centre, dedicated to rehabilitating orphaned elephants, where you can observe and learn about the conservation efforts and see baby elephants being cared for.
        `,
    img: "elephant-transit-centre.png",
    readmore: false,
    destination: 24
  },
  {
    id: 73,
    title: "Mahapelessa Hot Springs",
    desc: `
            Relax in the natural thermal waters of Mahapelessa Hot Springs, known for their therapeutic properties and serene setting amidst the tranquil surroundings of southern Sri Lanka.
        `,
    img: "mahapelessa-hot-springs.png",
    readmore: false,
    destination: 24
  },
  {
    id: 74,
    title: "Relax at Jungle Beach",
    desc: `
            Unwind at Jungle Beach, a secluded and picturesque beach offering a tranquil escape with clear waters and lush greenery, perfect for relaxation and swimming.
        `,
    img: "relax-at-jungle-beach.png",
    readmore: false,
    destination: 25
  },
  {
    id: 75,
    title: "Japanese Peace Pagoda Rumassala",
    desc: `
            Visit the Japanese Peace Pagoda in Rumassala, a serene Buddhist stupa offering panoramic views of the surrounding area and a peaceful atmosphere for reflection and meditation.
        `,
    img: "japanese-peace-pagoda.png",
    readmore: false,
    destination: 25
  },
  {
    id: 76,
    title: "Rope Swings at Dalawella and Wijeya Beach",
    desc: `
            Enjoy the fun of rope swings at Dalawella and Wijeya Beach, where you can swing out over the water and experience the thrill of a unique and exciting beach activity.
        `,
    img: "rope-swings.png",
    readmore: false,
    destination: 25
  },
  {
    id: 77,
    title: "Surfing Time",
    desc: `
            Experience the excitement of surfing in Sri Lanka’s prime surf spots, perfect for both beginners and experienced surfers looking to catch some great waves.
        `,
    img: "surfing-time.png",
    readmore: false,
    destination: 26
  },
  {
    id: 78,
    title: "Boat Safari in Madu River",
    desc: `
            Take a boat safari in Madu River, exploring the diverse ecosystems, mangrove forests, and wildlife along the river, providing a unique and scenic water adventure.
        `,
    img: "boat-safari.png",
    readmore: false,
    destination: 26
  },
  {
    id: 79,
    title: "Cinnamon Plantation Tour",
    desc: `
            Discover the world of cinnamon production on a plantation tour, learning about the process of harvesting and processing cinnamon, and experiencing the aromatic surroundings.
        `,
    img: "cinnamon-plantation-tour.png",
    readmore: false,
    destination: 26
  },
  {
    id: 80,
    title: "Galle Fort",
    desc: `
            Explore Galle Fort, a UNESCO World Heritage site with well-preserved colonial architecture, charming streets, and stunning ocean views, offering a blend of history and culture.
        `,
    img: "galle-fort.png",
    readmore: false,
    destination: 27
  },
  {
    id: 81,
    title: "Tropical Gardens of Galle",
    desc: `
            Visit the tropical gardens of Galle, known for their lush greenery, diverse plant species, and tranquil environment, perfect for a peaceful stroll amidst nature.
        `,
    img: "tropical-gardens.png",
    readmore: false,
    destination: 27
  },
  {
    id: 82,
    title: "Unawatuna Beach",
    desc: `
            Relax on Unawatuna Beach, famous for its golden sands and clear waters, ideal for swimming, sunbathing, and enjoying the vibrant coastal atmosphere.
        `,
    img: "unawatuna-beach.png",
    readmore: false,
    destination: 27
  }
];

export default exp;
